import L from 'leaflet';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import React, { ComponentProps } from 'react';
import { Marker as LeafletMarker } from 'react-leaflet';
import { Point } from '@compass/types/maps/Point';
import { useMap } from './MapContext';

// Need to correct image URLs
L.Icon.Default.prototype.options.iconUrl = iconUrl;
L.Icon.Default.prototype.options.shadowUrl = shadowUrl;

interface MarkerProps
  extends Omit<ComponentProps<typeof LeafletMarker>, 'position'> {
  point: Point;
}

export function Marker({ point, ...props }: MarkerProps): MarkerElement {
  const { image } = useMap();
  return <LeafletMarker position={point.toLatLng(image)} {...props} />;
}

export type MarkerElement = React.ReactElement<MarkerProps, typeof Marker>;
