import { getChildren, getFactors } from '@compass/data/maps/factors';
import { MapImage } from '@compass/data/maps/vanilla/images';
import { Descriptor } from '@compass/types/maps/Descriptor';

export class Registry {
  private descriptors: Record<string, Descriptor>;

  constructor(mapImages: Record<string, MapImage>) {
    this.descriptors = {};
    for (const [name, image] of Object.entries(mapImages)) {
      const factors = getFactors(name);
      this.descriptors[name] = {
        name,
        attribution: image.attribution,
        title: factors.name,
        url: image.url,
        getBoundaries: () => factors.boundaries,
        getSiblings: () => {
          if (factors.key === factors.parent) {
            return [];
          }
          return getChildren(factors.parent).map((child) => ({
            name: child.key,
            title: child.name,
          }));
        },
      };
    }
  }

  getDescriptor(name: string | undefined): Descriptor | undefined {
    if (!name) {
      return undefined;
    }
    return this.descriptors[name];
  }
}
