import altaieuUrl from '@compass/img/vanilla/altaieu.png';
import altarRoomUrl from '@compass/img/vanilla/altar_room.png';
import attohwaChasmUrl from '@compass/img/vanilla/attohwa_chasm.png';
import bastokMarketsUrl from '@compass/img/vanilla/bastok_markets.png';
import bastokMinesUrl from '@compass/img/vanilla/bastok_mines.png';
import batalliaDownsUrl from '@compass/img/vanilla/batallia_downs.png';
import beadeaux0Url from '@compass/img/vanilla/beadeaux_0.png';
import beadeaux1Url from '@compass/img/vanilla/beadeaux_1.png';
import beaucedineGlacierUrl from '@compass/img/vanilla/beaucedine_glacier.png';
import behemothsDominionUrl from '@compass/img/vanilla/behemoths_dominion.png';
import bibikiBay0Url from '@compass/img/vanilla/bibiki_bay_0.png';
import bibikiBay1Url from '@compass/img/vanilla/bibiki_bay_1.png';
import bostaunieuxOubliette0Url from '@compass/img/vanilla/bostaunieux_oubliette_0.png';
import bostaunieuxOubliette1Url from '@compass/img/vanilla/bostaunieux_oubliette_1.png';
import buburimuPeninsulaUrl from '@compass/img/vanilla/buburimu_peninsula.png';
import capeTerigganUrl from '@compass/img/vanilla/cape_teriggan.png';
import carpentersLanding0Url from '@compass/img/vanilla/carpenters_landing_0.png';
import carpentersLanding1Url from '@compass/img/vanilla/carpenters_landing_1.png';
import castleOztroja0Url from '@compass/img/vanilla/castle_oztroja_0.png';
import castleOztroja1Url from '@compass/img/vanilla/castle_oztroja_1.png';
import castleOztroja2Url from '@compass/img/vanilla/castle_oztroja_2.png';
import castleOztroja3Url from '@compass/img/vanilla/castle_oztroja_3.png';
import castleOztroja4Url from '@compass/img/vanilla/castle_oztroja_4.png';
import castleOztroja5Url from '@compass/img/vanilla/castle_oztroja_5.png';
import castleOztroja6Url from '@compass/img/vanilla/castle_oztroja_6.png';
import castleZvahlBaileys0Url from '@compass/img/vanilla/castle_zvahl_baileys_0.png';
import castleZvahlBaileys1Url from '@compass/img/vanilla/castle_zvahl_baileys_1.png';
import castleZvahlBaileys2Url from '@compass/img/vanilla/castle_zvahl_baileys_2.png';
import castleZvahlBaileys3Url from '@compass/img/vanilla/castle_zvahl_baileys_3.png';
import castleZvahlKeep0Url from '@compass/img/vanilla/castle_zvahl_keep_0.png';
import castleZvahlKeep1Url from '@compass/img/vanilla/castle_zvahl_keep_1.png';
import castleZvahlKeep2Url from '@compass/img/vanilla/castle_zvahl_keep_2.png';
import castleZvahlKeep3Url from '@compass/img/vanilla/castle_zvahl_keep_3.png';
import chateaudOraguilleUrl from '@compass/img/vanilla/chateau_doraguille.png';
import crawlersNest0Url from '@compass/img/vanilla/crawlers_nest_0.png';
import crawlersNest1Url from '@compass/img/vanilla/crawlers_nest_1.png';
import crawlersNest2Url from '@compass/img/vanilla/crawlers_nest_2.png';
import crawlersNest3Url from '@compass/img/vanilla/crawlers_nest_3.png';
import dangrufWadi0Url from '@compass/img/vanilla/dangruf_wadi_0.png';
import dangrufWadi1Url from '@compass/img/vanilla/dangruf_wadi_1.png';
import davoiUrl from '@compass/img/vanilla/davoi.png';
import denOfRancor0Url from '@compass/img/vanilla/den_of_rancor_0.png';
import denOfRancor1Url from '@compass/img/vanilla/den_of_rancor_1.png';
import denOfRancor2Url from '@compass/img/vanilla/den_of_rancor_2.png';
import denOfRancor3Url from '@compass/img/vanilla/den_of_rancor_3.png';
import denOfRancor4Url from '@compass/img/vanilla/den_of_rancor_4.png';
import denOfRancor5Url from '@compass/img/vanilla/den_of_rancor_5.png';
import denOfRancor6Url from '@compass/img/vanilla/den_of_rancor_6.png';
import denOfRancor7Url from '@compass/img/vanilla/den_of_rancor_7.png';
import denOfRancor8Url from '@compass/img/vanilla/den_of_rancor_8.png';
import dragonsAeryUrl from '@compass/img/vanilla/dragons_aery.png';
import eastRonfaureUrl from '@compass/img/vanilla/east_ronfaure.png';
import eastSarutabarutaUrl from '@compass/img/vanilla/east_sarutabaruta.png';
import easternAltepaDesertUrl from '@compass/img/vanilla/eastern_altepa_desert.png';
import feiyin0Url from '@compass/img/vanilla/feiyin_0.png';
import feiyin1Url from '@compass/img/vanilla/feiyin_1.png';
import feiyin2Url from '@compass/img/vanilla/feiyin_2.png';
import fortGhelsbaUrl from '@compass/img/vanilla/fort_ghelsba.png';
import garlaigeCitadel0Url from '@compass/img/vanilla/garlaige_citadel_0.png';
import garlaigeCitadel1Url from '@compass/img/vanilla/garlaige_citadel_1.png';
import garlaigeCitadel2Url from '@compass/img/vanilla/garlaige_citadel_2.png';
import garlaigeCitadel3Url from '@compass/img/vanilla/garlaige_citadel_3.png';
import garlaigeCitadel4Url from '@compass/img/vanilla/garlaige_citadel_4.png';
import ghelsbaOutpostUrl from '@compass/img/vanilla/ghelsba_outpost.png';
import giddeus0Url from '@compass/img/vanilla/giddeus_0.png';
import giddeus1Url from '@compass/img/vanilla/giddeus_1.png';
import grandPalaceOfHuXzoi0Url from '@compass/img/vanilla/grand_palace_of_huxzoi_0.png';
import grandPalaceOfHuXzoi1Url from '@compass/img/vanilla/grand_palace_of_huxzoi_1.png';
import grandPalaceOfHuXzoi2Url from '@compass/img/vanilla/grand_palace_of_huxzoi_2.png';
import gusgenMines0Url from '@compass/img/vanilla/gusgen_mines_0.png';
import gusgenMines1Url from '@compass/img/vanilla/gusgen_mines_1.png';
import gusgenMines2Url from '@compass/img/vanilla/gusgen_mines_2.png';
import gusgenMines3Url from '@compass/img/vanilla/gusgen_mines_3.png';
import gusgenMines4Url from '@compass/img/vanilla/gusgen_mines_4.png';
import gustavTunnel0Url from '@compass/img/vanilla/gustav_tunnel_0.png';
import gustavTunnel1Url from '@compass/img/vanilla/gustav_tunnel_1.png';
import hallOfTheGodsUrl from '@compass/img/vanilla/hall_of_the_gods.png';
import hallOfTransference0Url from '@compass/img/vanilla/hall_of_transference_0.png';
import hallOfTransference1Url from '@compass/img/vanilla/hall_of_transference_1.png';
import hallOfTransference2Url from '@compass/img/vanilla/hall_of_transference_2.png';
import ifritsCauldron0Url from '@compass/img/vanilla/ifrits_cauldron_0.png';
import ifritsCauldron1Url from '@compass/img/vanilla/ifrits_cauldron_1.png';
import ifritsCauldron2Url from '@compass/img/vanilla/ifrits_cauldron_2.png';
import ifritsCauldron3Url from '@compass/img/vanilla/ifrits_cauldron_3.png';
import ifritsCauldron4Url from '@compass/img/vanilla/ifrits_cauldron_4.png';
import ifritsCauldron5Url from '@compass/img/vanilla/ifrits_cauldron_5.png';
import ifritsCauldron6Url from '@compass/img/vanilla/ifrits_cauldron_6.png';
import ifritsCauldron7Url from '@compass/img/vanilla/ifrits_cauldron_7.png';
import ifritsCauldron8Url from '@compass/img/vanilla/ifrits_cauldron_8.png';
import innerHorutotoRuins0Url from '@compass/img/vanilla/inner_horutoto_ruins_0.png';
import innerHorutotoRuins1Url from '@compass/img/vanilla/inner_horutoto_ruins_1.png';
import innerHorutotoRuins2Url from '@compass/img/vanilla/inner_horutoto_ruins_2.png';
import innerHorutotoRuins3Url from '@compass/img/vanilla/inner_horutoto_ruins_3.png';
import jugnerForestUrl from '@compass/img/vanilla/jugner_forest.png';
import kazhamUrl from '@compass/img/vanilla/kazham.png';
import kingRanperresTomb0Url from '@compass/img/vanilla/king_ranperres_tomb_0.png';
import kingRanperresTomb1Url from '@compass/img/vanilla/king_ranperres_tomb_1.png';
import kingRanperresTomb2Url from '@compass/img/vanilla/king_ranperres_tomb_2.png';
import konschtatHighlandsUrl from '@compass/img/vanilla/konschtat_highlands.png';
import korrolokaTunnel0Url from '@compass/img/vanilla/korroloka_tunnel_0.png';
import korrolokaTunnel1Url from '@compass/img/vanilla/korroloka_tunnel_1.png';
import korrolokaTunnel2Url from '@compass/img/vanilla/korroloka_tunnel_2.png';
import korrolokaTunnel3Url from '@compass/img/vanilla/korroloka_tunnel_3.png';
import korrolokaTunnel4Url from '@compass/img/vanilla/korroloka_tunnel_4.png';
import korrolokaTunnel5Url from '@compass/img/vanilla/korroloka_tunnel_5.png';
import kuftalTunnel0Url from '@compass/img/vanilla/kuftal_tunnel_0.png';
import kuftalTunnel1Url from '@compass/img/vanilla/kuftal_tunnel_1.png';
import kuftalTunnel2Url from '@compass/img/vanilla/kuftal_tunnel_2.png';
import kuftalTunnel3Url from '@compass/img/vanilla/kuftal_tunnel_3.png';
import kuftalTunnel4Url from '@compass/img/vanilla/kuftal_tunnel_4.png';
import laTheinePlateauUrl from '@compass/img/vanilla/la_theine_plateau.png';
import labyrinthOfOnzozoUrl from '@compass/img/vanilla/labyrinth_of_onzozo.png';
import lowerDelkfuttsTower0Url from '@compass/img/vanilla/lower_delkfutts_tower_0.png';
import lowerDelkfuttsTower1Url from '@compass/img/vanilla/lower_delkfutts_tower_1.png';
import lowerDelkfuttsTower2Url from '@compass/img/vanilla/lower_delkfutts_tower_2.png';
import lowerDelkfuttsTower3Url from '@compass/img/vanilla/lower_delkfutts_tower_3.png';
import lowerJeunoUrl from '@compass/img/vanilla/lower_jeuno.png';
import lufaiseMeadowsUrl from '@compass/img/vanilla/lufaise_meadows.png';
import mazeOfShakhrami0Url from '@compass/img/vanilla/maze_of_shakhrami_0.png';
import mazeOfShakhrami1Url from '@compass/img/vanilla/maze_of_shakhrami_1.png';
import mazeOfShakhrami2Url from '@compass/img/vanilla/maze_of_shakhrami_2.png';
import meriphataudMountainsUrl from '@compass/img/vanilla/meriphataud_mountains.png';
import metalworksUrl from '@compass/img/vanilla/metalworks.png';
import mhauraUrl from '@compass/img/vanilla/mhaura.png';
import middleDelkfuttsTower0Url from '@compass/img/vanilla/middle_delkfutts_tower_0.png';
import middleDelkfuttsTower1Url from '@compass/img/vanilla/middle_delkfutts_tower_1.png';
import middleDelkfuttsTower2Url from '@compass/img/vanilla/middle_delkfutts_tower_2.png';
import middleDelkfuttsTower3Url from '@compass/img/vanilla/middle_delkfutts_tower_3.png';
import middleDelkfuttsTower4Url from '@compass/img/vanilla/middle_delkfutts_tower_4.png';
import middleDelkfuttsTower5Url from '@compass/img/vanilla/middle_delkfutts_tower_5.png';
import misareauxCoastUrl from '@compass/img/vanilla/misareaux_coast.png';
import monasticCavernUrl from '@compass/img/vanilla/monastic_cavern.png';
import newtonMovalpolosUrl from '@compass/img/vanilla/newton_movalpolos.png';
import noMapUrl from '@compass/img/vanilla/no_map.png';
import norgUrl from '@compass/img/vanilla/norg.png';
import northGustabergUrl from '@compass/img/vanilla/north_gustaberg.png';
import northernSandoriaUrl from '@compass/img/vanilla/northern_san_doria.png';
import oldtonMovalpolosUrl from '@compass/img/vanilla/oldton_movalpolos.png';
import ordellesCaves0Url from '@compass/img/vanilla/ordelles_caves_0.png';
import ordellesCaves1Url from '@compass/img/vanilla/ordelles_caves_1.png';
import ordellesCaves2Url from '@compass/img/vanilla/ordelles_caves_2.png';
import ordellesCaves3Url from '@compass/img/vanilla/ordelles_caves_3.png';
import outerHorutotoRuins0Url from '@compass/img/vanilla/outer_horutoto_ruins_0.png';
import outerHorutotoRuins1Url from '@compass/img/vanilla/outer_horutoto_ruins_1.png';
import outerHorutotoRuins2Url from '@compass/img/vanilla/outer_horutoto_ruins_2.png';
import outerHorutotoRuins3Url from '@compass/img/vanilla/outer_horutoto_ruins_3.png';
import outerHorutotoRuins4Url from '@compass/img/vanilla/outer_horutoto_ruins_4.png';
import outerHorutotoRuins5Url from '@compass/img/vanilla/outer_horutoto_ruins_5.png';
import palboroughMines0Url from '@compass/img/vanilla/palborough_mines_0.png';
import palboroughMines1Url from '@compass/img/vanilla/palborough_mines_1.png';
import palboroughMines2Url from '@compass/img/vanilla/palborough_mines_2.png';
import pashhowMarshlandsUrl from '@compass/img/vanilla/pashhow_marshlands.png';
import phomiunaAqueducts0Url from '@compass/img/vanilla/phomiuna_aqueducts_0.png';
import phomiunaAqueducts1Url from '@compass/img/vanilla/phomiuna_aqueducts_1.png';
import phomiunaAqueducts2Url from '@compass/img/vanilla/phomiuna_aqueducts_2.png';
import portBastokUrl from '@compass/img/vanilla/port_bastok.png';
import portJeunoUrl from '@compass/img/vanilla/port_jeuno.png';
import portSandoriaUrl from '@compass/img/vanilla/port_san_doria.png';
import portWindurstUrl from '@compass/img/vanilla/port_windurst.png';
import promyvionDemUrl from '@compass/img/vanilla/promyvion_dem.png';
import promyvionHollaUrl from '@compass/img/vanilla/promyvion_holla.png';
import promyvionMeaUrl from '@compass/img/vanilla/promyvion_mea.png';
import promyvionVahzlUrl from '@compass/img/vanilla/promyvion_vahzl.png';
import psoxja0Url from '@compass/img/vanilla/psoxja_0.png';
import psoxja1Url from '@compass/img/vanilla/psoxja_1.png';
import psoxja10Url from '@compass/img/vanilla/psoxja_10.png';
import psoxja11Url from '@compass/img/vanilla/psoxja_11.png';
import psoxja13Url from '@compass/img/vanilla/psoxja_13.png';
import psoxja15Url from '@compass/img/vanilla/psoxja_15.png';
import psoxja3Url from '@compass/img/vanilla/psoxja_3.png';
import psoxja4Url from '@compass/img/vanilla/psoxja_4.png';
import psoxja5Url from '@compass/img/vanilla/psoxja_5.png';
import psoxja7Url from '@compass/img/vanilla/psoxja_7.png';
import psoxja8Url from '@compass/img/vanilla/psoxja_8.png';
import psoxja9Url from '@compass/img/vanilla/psoxja_9.png';
import qufimIslandUrl from '@compass/img/vanilla/qufim_island.png';
import quicksandCaves0Url from '@compass/img/vanilla/quicksand_caves_0.png';
import quicksandCaves1Url from '@compass/img/vanilla/quicksand_caves_1.png';
import quicksandCaves2Url from '@compass/img/vanilla/quicksand_caves_2.png';
import quicksandCaves3Url from '@compass/img/vanilla/quicksand_caves_3.png';
import quicksandCaves4Url from '@compass/img/vanilla/quicksand_caves_4.png';
import quicksandCaves5Url from '@compass/img/vanilla/quicksand_caves_5.png';
import quicksandCaves6Url from '@compass/img/vanilla/quicksand_caves_6.png';
import quicksandCaves7Url from '@compass/img/vanilla/quicksand_caves_7.png';
import qulunDomeUrl from '@compass/img/vanilla/qulun_dome.png';
import rabaoUrl from '@compass/img/vanilla/rabao.png';
import ranguemontPassUrl from '@compass/img/vanilla/ranguemont_pass.png';
import riverneSiteA010Url from '@compass/img/vanilla/riverne_site_a01_0.png';
import riverneSiteA011Url from '@compass/img/vanilla/riverne_site_a01_1.png';
import riverneSiteB010Url from '@compass/img/vanilla/riverne_site_b01_0.png';
import riverneSiteB011Url from '@compass/img/vanilla/riverne_site_b01_1.png';
import rolanberryFieldsUrl from '@compass/img/vanilla/rolanberry_fields.png';
import romaeveUrl from '@compass/img/vanilla/romaeve.png';
import ruaunGardensUrl from '@compass/img/vanilla/ruaun_gardens.png';
import ruludeGardensUrl from '@compass/img/vanilla/rulude_gardens.png';
import sacrarium0Url from '@compass/img/vanilla/sacrarium_0.png';
import sacrarium1Url from '@compass/img/vanilla/sacrarium_1.png';
import sauromugueChampaignUrl from '@compass/img/vanilla/sauromugue_champaign.png';
import seaSerpentGrotto0Url from '@compass/img/vanilla/sea_serpent_grotto_0.png';
import seaSerpentGrotto1Url from '@compass/img/vanilla/sea_serpent_grotto_1.png';
import seaSerpentGrotto2Url from '@compass/img/vanilla/sea_serpent_grotto_2.png';
import seaSerpentGrotto3Url from '@compass/img/vanilla/sea_serpent_grotto_3.png';
import seaSerpentGrotto4Url from '@compass/img/vanilla/sea_serpent_grotto_4.png';
import sealionsDenUrl from '@compass/img/vanilla/sealions_den.png';
import selbinaUrl from '@compass/img/vanilla/selbina.png';
import southGustabergUrl from '@compass/img/vanilla/south_gustaberg.png';
import southernSandoriaUrl from '@compass/img/vanilla/southern_san_doria.png';
import tahrongiCanyonUrl from '@compass/img/vanilla/tahrongi_canyon.png';
import tavnazianSafehold0Url from '@compass/img/vanilla/tavnazian_safehold_0.png';
import tavnazianSafehold1Url from '@compass/img/vanilla/tavnazian_safehold_1.png';
import tavnazianSafehold2Url from '@compass/img/vanilla/tavnazian_safehold_2.png';
import templeOfUggalepih0Url from '@compass/img/vanilla/temple_of_uggalepih_0.png';
import templeOfUggalepih1Url from '@compass/img/vanilla/temple_of_uggalepih_1.png';
import templeOfUggalepih2Url from '@compass/img/vanilla/temple_of_uggalepih_2.png';
import templeOfUggalepih3Url from '@compass/img/vanilla/temple_of_uggalepih_3.png';
import theBoyahdaTree0Url from '@compass/img/vanilla/the_boyahda_tree_0.png';
import theBoyahdaTree1Url from '@compass/img/vanilla/the_boyahda_tree_1.png';
import theBoyahdaTree2Url from '@compass/img/vanilla/the_boyahda_tree_2.png';
import theBoyahdaTree3Url from '@compass/img/vanilla/the_boyahda_tree_3.png';
import theEldiemeNecropolis0Url from '@compass/img/vanilla/the_eldieme_necropolis_0.png';
import theEldiemeNecropolis1Url from '@compass/img/vanilla/the_eldieme_necropolis_1.png';
import theEldiemeNecropolis2Url from '@compass/img/vanilla/the_eldieme_necropolis_2.png';
import theEldiemeNecropolis3Url from '@compass/img/vanilla/the_eldieme_necropolis_3.png';
import theGardenOfRuhmet0Url from '@compass/img/vanilla/the_garden_of_ruhmet_0.png';
import theGardenOfRuhmet1Url from '@compass/img/vanilla/the_garden_of_ruhmet_1.png';
import theGardenOfRuhmet2Url from '@compass/img/vanilla/the_garden_of_ruhmet_2.png';
import theGardenOfRuhmet3Url from '@compass/img/vanilla/the_garden_of_ruhmet_3.png';
import theSanctuaryOfZitahUrl from '@compass/img/vanilla/the_sanctuary_of_zitah.png';
import theShrineOfRuavitau0Url from '@compass/img/vanilla/the_shrine_of_ruavitau_0.png';
import theShrineOfRuavitau1Url from '@compass/img/vanilla/the_shrine_of_ruavitau_1.png';
import theShrineOfRuavitau2Url from '@compass/img/vanilla/the_shrine_of_ruavitau_2.png';
import theShrineOfRuavitau3Url from '@compass/img/vanilla/the_shrine_of_ruavitau_3.png';
import theShrineOfRuavitau4Url from '@compass/img/vanilla/the_shrine_of_ruavitau_4.png';
import theShrineOfRuavitau5Url from '@compass/img/vanilla/the_shrine_of_ruavitau_5.png';
import toraimaraiCanal0Url from '@compass/img/vanilla/toraimarai_canal_0.png';
import toraimaraiCanal1Url from '@compass/img/vanilla/toraimarai_canal_1.png';
import uleguerandRangeUrl from '@compass/img/vanilla/uleguerand_range.png';
import upperDelkfuttsTower0Url from '@compass/img/vanilla/upper_delkfutts_tower_0.png';
import upperDelkfuttsTower1Url from '@compass/img/vanilla/upper_delkfutts_tower_1.png';
import upperDelkfuttsTower2Url from '@compass/img/vanilla/upper_delkfutts_tower_2.png';
import upperDelkfuttsTower3Url from '@compass/img/vanilla/upper_delkfutts_tower_3.png';
import upperDelkfuttsTower4Url from '@compass/img/vanilla/upper_delkfutts_tower_4.png';
import upperDelkfuttsTower5Url from '@compass/img/vanilla/upper_delkfutts_tower_5.png';
import upperJeunoUrl from '@compass/img/vanilla/upper_jeuno.png';
import valkurmDunesUrl from '@compass/img/vanilla/valkurm_dunes.png';
import valleyOfSorrowsUrl from '@compass/img/vanilla/valley_of_sorrows.png';
import velugannonPalace0Url from '@compass/img/vanilla/velugannon_palace_0.png';
import velugannonPalace1Url from '@compass/img/vanilla/velugannon_palace_1.png';
import velugannonPalace2Url from '@compass/img/vanilla/velugannon_palace_2.png';
import velugannonPalace3Url from '@compass/img/vanilla/velugannon_palace_3.png';
import velugannonPalace4Url from '@compass/img/vanilla/velugannon_palace_4.png';
import velugannonPalace5Url from '@compass/img/vanilla/velugannon_palace_5.png';
import velugannonPalace6Url from '@compass/img/vanilla/velugannon_palace_6.png';
import velugannonPalace7Url from '@compass/img/vanilla/velugannon_palace_7.png';
import velugannonPalace8Url from '@compass/img/vanilla/velugannon_palace_8.png';
import westRonfaureUrl from '@compass/img/vanilla/west_ronfaure.png';
import westSarutabarutaUrl from '@compass/img/vanilla/west_sarutabaruta.png';
import westernAltepaDesertUrl from '@compass/img/vanilla/western_altepa_desert.png';
import windurstWallsUrl from '@compass/img/vanilla/windurst_walls.png';
import windurstWaters0Url from '@compass/img/vanilla/windurst_waters_0.png';
import windurstWaters1Url from '@compass/img/vanilla/windurst_waters_1.png';
import windurstWaters2Url from '@compass/img/vanilla/windurst_waters_2.png';
import windurstWoodsUrl from '@compass/img/vanilla/windurst_woods.png';
import xarcabardUrl from '@compass/img/vanilla/xarcabard.png';
import yhoatorJungleUrl from '@compass/img/vanilla/yhoator_jungle.png';
import yughottGrotto0Url from '@compass/img/vanilla/yughott_grotto_0.png';
import yughottGrotto1Url from '@compass/img/vanilla/yughott_grotto_1.png';
import yuhtungaJungleUrl from '@compass/img/vanilla/yuhtunga_jungle.png';
import zeruhnMinesUrl from '@compass/img/vanilla/zeruhn_mines.png';

const attribution = '© Square Enix';

export interface MapImage {
  attribution: string;
  url: string;
}

export const noMap: MapImage = {
  attribution,
  url: noMapUrl,
};

export const mapImages: Record<string, MapImage> = {
  altaieu: {
    attribution,
    url: altaieuUrl,
  },
  altar_room: {
    attribution,
    url: altarRoomUrl,
  },
  attohwa_chasm: {
    attribution,
    url: attohwaChasmUrl,
  },
  bastok_markets: {
    attribution,
    url: bastokMarketsUrl,
  },
  bastok_mines: {
    attribution,
    url: bastokMinesUrl,
  },
  batallia_downs: {
    attribution,
    url: batalliaDownsUrl,
  },
  beadeaux_0: {
    attribution,
    url: beadeaux0Url,
  },
  beadeaux_1: {
    attribution,
    url: beadeaux1Url,
  },
  beaucedine_glacier: {
    attribution,
    url: beaucedineGlacierUrl,
  },
  behemoths_dominion: {
    attribution,
    url: behemothsDominionUrl,
  },
  bibiki_bay_0: {
    attribution,
    url: bibikiBay0Url,
  },
  bibiki_bay_1: {
    attribution,
    url: bibikiBay1Url,
  },
  bostaunieux_oubliette_0: {
    attribution,
    url: bostaunieuxOubliette0Url,
  },
  bostaunieux_oubliette_1: {
    attribution,
    url: bostaunieuxOubliette1Url,
  },
  buburimu_peninsula: {
    attribution,
    url: buburimuPeninsulaUrl,
  },
  cape_teriggan: {
    attribution,
    url: capeTerigganUrl,
  },
  carpenters_landing_0: {
    attribution,
    url: carpentersLanding0Url,
  },
  carpenters_landing_1: {
    attribution,
    url: carpentersLanding1Url,
  },
  castle_oztroja_0: {
    attribution,
    url: castleOztroja0Url,
  },
  castle_oztroja_1: {
    attribution,
    url: castleOztroja1Url,
  },
  castle_oztroja_2: {
    attribution,
    url: castleOztroja2Url,
  },
  castle_oztroja_3: {
    attribution,
    url: castleOztroja3Url,
  },
  castle_oztroja_4: {
    attribution,
    url: castleOztroja4Url,
  },
  castle_oztroja_5: {
    attribution,
    url: castleOztroja5Url,
  },
  castle_oztroja_6: {
    attribution,
    url: castleOztroja6Url,
  },
  castle_zvahl_baileys_0: {
    attribution,
    url: castleZvahlBaileys0Url,
  },
  castle_zvahl_baileys_1: {
    attribution,
    url: castleZvahlBaileys1Url,
  },
  castle_zvahl_baileys_2: {
    attribution,
    url: castleZvahlBaileys2Url,
  },
  castle_zvahl_baileys_3: {
    attribution,
    url: castleZvahlBaileys3Url,
  },
  castle_zvahl_keep_0: {
    attribution,
    url: castleZvahlKeep0Url,
  },
  castle_zvahl_keep_1: {
    attribution,
    url: castleZvahlKeep1Url,
  },
  castle_zvahl_keep_2: {
    attribution,
    url: castleZvahlKeep2Url,
  },
  castle_zvahl_keep_3: {
    attribution,
    url: castleZvahlKeep3Url,
  },
  chateau_doraguille: {
    attribution,
    url: chateaudOraguilleUrl,
  },
  crawlers_nest_0: {
    attribution,
    url: crawlersNest0Url,
  },
  crawlers_nest_1: {
    attribution,
    url: crawlersNest1Url,
  },
  crawlers_nest_2: {
    attribution,
    url: crawlersNest2Url,
  },
  crawlers_nest_3: {
    attribution,
    url: crawlersNest3Url,
  },
  dangruf_wadi_0: {
    attribution,
    url: dangrufWadi0Url,
  },
  dangruf_wadi_1: {
    attribution,
    url: dangrufWadi1Url,
  },
  davoi: {
    attribution,
    url: davoiUrl,
  },
  den_of_rancor_0: {
    attribution,
    url: denOfRancor0Url,
  },
  den_of_rancor_1: {
    attribution,
    url: denOfRancor1Url,
  },
  den_of_rancor_2: {
    attribution,
    url: denOfRancor2Url,
  },
  den_of_rancor_3: {
    attribution,
    url: denOfRancor3Url,
  },
  den_of_rancor_4: {
    attribution,
    url: denOfRancor4Url,
  },
  den_of_rancor_5: {
    attribution,
    url: denOfRancor5Url,
  },
  den_of_rancor_6: {
    attribution,
    url: denOfRancor6Url,
  },
  den_of_rancor_7: {
    attribution,
    url: denOfRancor7Url,
  },
  den_of_rancor_8: {
    attribution,
    url: denOfRancor8Url,
  },
  dragons_aery: {
    attribution,
    url: dragonsAeryUrl,
  },
  eastern_altepa_desert: {
    attribution,
    url: easternAltepaDesertUrl,
  },
  east_ronfaure: {
    attribution,
    url: eastRonfaureUrl,
  },
  east_sarutabaruta: {
    attribution,
    url: eastSarutabarutaUrl,
  },
  feiyin_0: {
    attribution,
    url: feiyin0Url,
  },
  feiyin_1: {
    attribution,
    url: feiyin1Url,
  },
  feiyin_2: {
    attribution,
    url: feiyin2Url,
  },
  fort_ghelsba: {
    attribution,
    url: fortGhelsbaUrl,
  },
  garlaige_citadel_0: {
    attribution,
    url: garlaigeCitadel0Url,
  },
  garlaige_citadel_1: {
    attribution,
    url: garlaigeCitadel1Url,
  },
  garlaige_citadel_2: {
    attribution,
    url: garlaigeCitadel2Url,
  },
  garlaige_citadel_3: {
    attribution,
    url: garlaigeCitadel3Url,
  },
  garlaige_citadel_4: {
    attribution,
    url: garlaigeCitadel4Url,
  },
  ghelsba_outpost: {
    attribution,
    url: ghelsbaOutpostUrl,
  },
  giddeus_0: {
    attribution,
    url: giddeus0Url,
  },
  giddeus_1: {
    attribution,
    url: giddeus1Url,
  },
  grand_palace_of_huxzoi_0: {
    attribution,
    url: grandPalaceOfHuXzoi0Url,
  },
  grand_palace_of_huxzoi_1: {
    attribution,
    url: grandPalaceOfHuXzoi1Url,
  },
  grand_palace_of_huxzoi_2: {
    attribution,
    url: grandPalaceOfHuXzoi2Url,
  },
  gusgen_mines_0: {
    attribution,
    url: gusgenMines0Url,
  },
  gusgen_mines_1: {
    attribution,
    url: gusgenMines1Url,
  },
  gusgen_mines_2: {
    attribution,
    url: gusgenMines2Url,
  },
  gusgen_mines_3: {
    attribution,
    url: gusgenMines3Url,
  },
  gusgen_mines_4: {
    attribution,
    url: gusgenMines4Url,
  },
  gustav_tunnel_0: {
    attribution,
    url: gustavTunnel0Url,
  },
  gustav_tunnel_1: {
    attribution,
    url: gustavTunnel1Url,
  },
  hall_of_the_gods: {
    attribution,
    url: hallOfTheGodsUrl,
  },
  hall_of_transference_0: {
    attribution,
    url: hallOfTransference0Url,
  },
  hall_of_transference_1: {
    attribution,
    url: hallOfTransference1Url,
  },
  hall_of_transference_2: {
    attribution,
    url: hallOfTransference2Url,
  },
  ifrits_cauldron_0: {
    attribution,
    url: ifritsCauldron0Url,
  },
  ifrits_cauldron_1: {
    attribution,
    url: ifritsCauldron1Url,
  },
  ifrits_cauldron_2: {
    attribution,
    url: ifritsCauldron2Url,
  },
  ifrits_cauldron_3: {
    attribution,
    url: ifritsCauldron3Url,
  },
  ifrits_cauldron_4: {
    attribution,
    url: ifritsCauldron4Url,
  },
  ifrits_cauldron_5: {
    attribution,
    url: ifritsCauldron5Url,
  },
  ifrits_cauldron_6: {
    attribution,
    url: ifritsCauldron6Url,
  },
  ifrits_cauldron_7: {
    attribution,
    url: ifritsCauldron7Url,
  },
  ifrits_cauldron_8: {
    attribution,
    url: ifritsCauldron8Url,
  },
  inner_horutoto_ruins_0: {
    attribution,
    url: innerHorutotoRuins0Url,
  },
  inner_horutoto_ruins_1: {
    attribution,
    url: innerHorutotoRuins1Url,
  },
  inner_horutoto_ruins_2: {
    attribution,
    url: innerHorutotoRuins2Url,
  },
  inner_horutoto_ruins_3: {
    attribution,
    url: innerHorutotoRuins3Url,
  },
  jugner_forest: {
    attribution,
    url: jugnerForestUrl,
  },
  kazham: {
    attribution,
    url: kazhamUrl,
  },
  king_ranperres_tomb_0: {
    attribution,
    url: kingRanperresTomb0Url,
  },
  king_ranperres_tomb_1: {
    attribution,
    url: kingRanperresTomb1Url,
  },
  king_ranperres_tomb_2: {
    attribution,
    url: kingRanperresTomb2Url,
  },
  konschtat_highlands: {
    attribution,
    url: konschtatHighlandsUrl,
  },
  korroloka_tunnel_0: {
    attribution,
    url: korrolokaTunnel0Url,
  },
  korroloka_tunnel_1: {
    attribution,
    url: korrolokaTunnel1Url,
  },
  korroloka_tunnel_2: {
    attribution,
    url: korrolokaTunnel2Url,
  },
  korroloka_tunnel_3: {
    attribution,
    url: korrolokaTunnel3Url,
  },
  korroloka_tunnel_4: {
    attribution,
    url: korrolokaTunnel4Url,
  },
  korroloka_tunnel_5: {
    attribution,
    url: korrolokaTunnel5Url,
  },
  kuftal_tunnel_0: {
    attribution,
    url: kuftalTunnel0Url,
  },
  kuftal_tunnel_1: {
    attribution,
    url: kuftalTunnel1Url,
  },
  kuftal_tunnel_2: {
    attribution,
    url: kuftalTunnel2Url,
  },
  kuftal_tunnel_3: {
    attribution,
    url: kuftalTunnel3Url,
  },
  kuftal_tunnel_4: {
    attribution,
    url: kuftalTunnel4Url,
  },
  labyrinth_of_onzozo: {
    attribution,
    url: labyrinthOfOnzozoUrl,
  },
  la_theine_plateau: {
    attribution,
    url: laTheinePlateauUrl,
  },
  lower_delkfutts_tower_0: {
    attribution,
    url: lowerDelkfuttsTower0Url,
  },
  lower_delkfutts_tower_1: {
    attribution,
    url: lowerDelkfuttsTower1Url,
  },
  lower_delkfutts_tower_2: {
    attribution,
    url: lowerDelkfuttsTower2Url,
  },
  lower_delkfutts_tower_3: {
    attribution,
    url: lowerDelkfuttsTower3Url,
  },
  lower_jeuno: {
    attribution,
    url: lowerJeunoUrl,
  },
  lufaise_meadows: {
    attribution,
    url: lufaiseMeadowsUrl,
  },
  maze_of_shakhrami_0: {
    attribution,
    url: mazeOfShakhrami0Url,
  },
  maze_of_shakhrami_1: {
    attribution,
    url: mazeOfShakhrami1Url,
  },
  maze_of_shakhrami_2: {
    attribution,
    url: mazeOfShakhrami2Url,
  },
  meriphataud_mountains: {
    attribution,
    url: meriphataudMountainsUrl,
  },
  metalworks: {
    attribution,
    url: metalworksUrl,
  },
  mhaura: {
    attribution,
    url: mhauraUrl,
  },
  middle_delkfutts_tower_0: {
    attribution,
    url: middleDelkfuttsTower0Url,
  },
  middle_delkfutts_tower_1: {
    attribution,
    url: middleDelkfuttsTower1Url,
  },
  middle_delkfutts_tower_2: {
    attribution,
    url: middleDelkfuttsTower2Url,
  },
  middle_delkfutts_tower_3: {
    attribution,
    url: middleDelkfuttsTower3Url,
  },
  middle_delkfutts_tower_4: {
    attribution,
    url: middleDelkfuttsTower4Url,
  },
  middle_delkfutts_tower_5: {
    attribution,
    url: middleDelkfuttsTower5Url,
  },
  misareaux_coast: {
    attribution,
    url: misareauxCoastUrl,
  },
  monastic_cavern: {
    attribution,
    url: monasticCavernUrl,
  },
  newton_movalpolos: {
    attribution,
    url: newtonMovalpolosUrl,
  },
  norg: {
    attribution,
    url: norgUrl,
  },
  northern_san_doria: {
    attribution,
    url: northernSandoriaUrl,
  },
  north_gustaberg: {
    attribution,
    url: northGustabergUrl,
  },
  oldton_movalpolos: {
    attribution,
    url: oldtonMovalpolosUrl,
  },
  ordelles_caves_0: {
    attribution,
    url: ordellesCaves0Url,
  },
  ordelles_caves_1: {
    attribution,
    url: ordellesCaves1Url,
  },
  ordelles_caves_2: {
    attribution,
    url: ordellesCaves2Url,
  },
  ordelles_caves_3: {
    attribution,
    url: ordellesCaves3Url,
  },
  outer_horutoto_ruins_0: {
    attribution,
    url: outerHorutotoRuins0Url,
  },
  outer_horutoto_ruins_1: {
    attribution,
    url: outerHorutotoRuins1Url,
  },
  outer_horutoto_ruins_2: {
    attribution,
    url: outerHorutotoRuins2Url,
  },
  outer_horutoto_ruins_3: {
    attribution,
    url: outerHorutotoRuins3Url,
  },
  outer_horutoto_ruins_4: {
    attribution,
    url: outerHorutotoRuins4Url,
  },
  outer_horutoto_ruins_5: {
    attribution,
    url: outerHorutotoRuins5Url,
  },
  palborough_mines_0: {
    attribution,
    url: palboroughMines0Url,
  },
  palborough_mines_1: {
    attribution,
    url: palboroughMines1Url,
  },
  palborough_mines_2: {
    attribution,
    url: palboroughMines2Url,
  },
  pashhow_marshlands: {
    attribution,
    url: pashhowMarshlandsUrl,
  },
  phomiuna_aqueducts_0: {
    attribution,
    url: phomiunaAqueducts0Url,
  },
  phomiuna_aqueducts_1: {
    attribution,
    url: phomiunaAqueducts1Url,
  },
  phomiuna_aqueducts_2: {
    attribution,
    url: phomiunaAqueducts2Url,
  },
  port_bastok: {
    attribution,
    url: portBastokUrl,
  },
  port_jeuno: {
    attribution,
    url: portJeunoUrl,
  },
  port_san_doria: {
    attribution,
    url: portSandoriaUrl,
  },
  port_windurst: {
    attribution,
    url: portWindurstUrl,
  },
  promyvion_dem: {
    attribution,
    url: promyvionDemUrl,
  },
  promyvion_holla: {
    attribution,
    url: promyvionHollaUrl,
  },
  promyvion_mea: {
    attribution,
    url: promyvionMeaUrl,
  },
  promyvion_vahzl: {
    attribution,
    url: promyvionVahzlUrl,
  },
  psoxja_0: {
    attribution,
    url: psoxja0Url,
  },
  psoxja_10: {
    attribution,
    url: psoxja10Url,
  },
  psoxja_11: {
    attribution,
    url: psoxja11Url,
  },
  psoxja_13: {
    attribution,
    url: psoxja13Url,
  },
  psoxja_15: {
    attribution,
    url: psoxja15Url,
  },
  psoxja_1: {
    attribution,
    url: psoxja1Url,
  },
  psoxja_3: {
    attribution,
    url: psoxja3Url,
  },
  psoxja_4: {
    attribution,
    url: psoxja4Url,
  },
  psoxja_5: {
    attribution,
    url: psoxja5Url,
  },
  psoxja_7: {
    attribution,
    url: psoxja7Url,
  },
  psoxja_8: {
    attribution,
    url: psoxja8Url,
  },
  psoxja_9: {
    attribution,
    url: psoxja9Url,
  },
  qufim_island: {
    attribution,
    url: qufimIslandUrl,
  },
  quicksand_caves_0: {
    attribution,
    url: quicksandCaves0Url,
  },
  quicksand_caves_1: {
    attribution,
    url: quicksandCaves1Url,
  },
  quicksand_caves_2: {
    attribution,
    url: quicksandCaves2Url,
  },
  quicksand_caves_3: {
    attribution,
    url: quicksandCaves3Url,
  },
  quicksand_caves_4: {
    attribution,
    url: quicksandCaves4Url,
  },
  quicksand_caves_5: {
    attribution,
    url: quicksandCaves5Url,
  },
  quicksand_caves_6: {
    attribution,
    url: quicksandCaves6Url,
  },
  quicksand_caves_7: {
    attribution,
    url: quicksandCaves7Url,
  },
  qulun_dome: {
    attribution,
    url: qulunDomeUrl,
  },
  rabao: {
    attribution,
    url: rabaoUrl,
  },
  ranguemont_pass: {
    attribution,
    url: ranguemontPassUrl,
  },
  riverne_site_a01_0: {
    attribution,
    url: riverneSiteA010Url,
  },
  riverne_site_a01_1: {
    attribution,
    url: riverneSiteA011Url,
  },
  riverne_site_b01_0: {
    attribution,
    url: riverneSiteB010Url,
  },
  riverne_site_b01_1: {
    attribution,
    url: riverneSiteB011Url,
  },
  rolanberry_fields: {
    attribution,
    url: rolanberryFieldsUrl,
  },
  romaeve: {
    attribution,
    url: romaeveUrl,
  },
  ruaun_gardens: {
    attribution,
    url: ruaunGardensUrl,
  },
  rulude_gardens: {
    attribution,
    url: ruludeGardensUrl,
  },
  sacrarium_0: {
    attribution,
    url: sacrarium0Url,
  },
  sacrarium_1: {
    attribution,
    url: sacrarium1Url,
  },
  sauromugue_champaign: {
    attribution,
    url: sauromugueChampaignUrl,
  },
  sealions_den: {
    attribution,
    url: sealionsDenUrl,
  },
  sea_serpent_grotto_0: {
    attribution,
    url: seaSerpentGrotto0Url,
  },
  sea_serpent_grotto_1: {
    attribution,
    url: seaSerpentGrotto1Url,
  },
  sea_serpent_grotto_2: {
    attribution,
    url: seaSerpentGrotto2Url,
  },
  sea_serpent_grotto_3: {
    attribution,
    url: seaSerpentGrotto3Url,
  },
  sea_serpent_grotto_4: {
    attribution,
    url: seaSerpentGrotto4Url,
  },
  selbina: {
    attribution,
    url: selbinaUrl,
  },
  southern_san_doria: {
    attribution,
    url: southernSandoriaUrl,
  },
  south_gustaberg: {
    attribution,
    url: southGustabergUrl,
  },
  tahrongi_canyon: {
    attribution,
    url: tahrongiCanyonUrl,
  },
  tavnazian_safehold_0: {
    attribution,
    url: tavnazianSafehold0Url,
  },
  tavnazian_safehold_1: {
    attribution,
    url: tavnazianSafehold1Url,
  },
  tavnazian_safehold_2: {
    attribution,
    url: tavnazianSafehold2Url,
  },
  temple_of_uggalepih_0: {
    attribution,
    url: templeOfUggalepih0Url,
  },
  temple_of_uggalepih_1: {
    attribution,
    url: templeOfUggalepih1Url,
  },
  temple_of_uggalepih_2: {
    attribution,
    url: templeOfUggalepih2Url,
  },
  temple_of_uggalepih_3: {
    attribution,
    url: templeOfUggalepih3Url,
  },
  the_boyahda_tree_0: {
    attribution,
    url: theBoyahdaTree0Url,
  },
  the_boyahda_tree_1: {
    attribution,
    url: theBoyahdaTree1Url,
  },
  the_boyahda_tree_2: {
    attribution,
    url: theBoyahdaTree2Url,
  },
  the_boyahda_tree_3: {
    attribution,
    url: theBoyahdaTree3Url,
  },
  the_eldieme_necropolis_0: {
    attribution,
    url: theEldiemeNecropolis0Url,
  },
  the_eldieme_necropolis_1: {
    attribution,
    url: theEldiemeNecropolis1Url,
  },
  the_eldieme_necropolis_2: {
    attribution,
    url: theEldiemeNecropolis2Url,
  },
  the_eldieme_necropolis_3: {
    attribution,
    url: theEldiemeNecropolis3Url,
  },
  the_garden_of_ruhmet_0: {
    attribution,
    url: theGardenOfRuhmet0Url,
  },
  the_garden_of_ruhmet_1: {
    attribution,
    url: theGardenOfRuhmet1Url,
  },
  the_garden_of_ruhmet_2: {
    attribution,
    url: theGardenOfRuhmet2Url,
  },
  the_garden_of_ruhmet_3: {
    attribution,
    url: theGardenOfRuhmet3Url,
  },
  the_sanctuary_of_zitah: {
    attribution,
    url: theSanctuaryOfZitahUrl,
  },
  the_shrine_of_ruavitau_0: {
    attribution,
    url: theShrineOfRuavitau0Url,
  },
  the_shrine_of_ruavitau_1: {
    attribution,
    url: theShrineOfRuavitau1Url,
  },
  the_shrine_of_ruavitau_2: {
    attribution,
    url: theShrineOfRuavitau2Url,
  },
  the_shrine_of_ruavitau_3: {
    attribution,
    url: theShrineOfRuavitau3Url,
  },
  the_shrine_of_ruavitau_4: {
    attribution,
    url: theShrineOfRuavitau4Url,
  },
  the_shrine_of_ruavitau_5: {
    attribution,
    url: theShrineOfRuavitau5Url,
  },
  toraimarai_canal_0: {
    attribution,
    url: toraimaraiCanal0Url,
  },
  toraimarai_canal_1: {
    attribution,
    url: toraimaraiCanal1Url,
  },
  uleguerand_range: {
    attribution,
    url: uleguerandRangeUrl,
  },
  upper_delkfutts_tower_0: {
    attribution,
    url: upperDelkfuttsTower0Url,
  },
  upper_delkfutts_tower_1: {
    attribution,
    url: upperDelkfuttsTower1Url,
  },
  upper_delkfutts_tower_2: {
    attribution,
    url: upperDelkfuttsTower2Url,
  },
  upper_delkfutts_tower_3: {
    attribution,
    url: upperDelkfuttsTower3Url,
  },
  upper_delkfutts_tower_4: {
    attribution,
    url: upperDelkfuttsTower4Url,
  },
  upper_delkfutts_tower_5: {
    attribution,
    url: upperDelkfuttsTower5Url,
  },
  upper_jeuno: {
    attribution,
    url: upperJeunoUrl,
  },
  valkurm_dunes: {
    attribution,
    url: valkurmDunesUrl,
  },
  valley_of_sorrows: {
    attribution,
    url: valleyOfSorrowsUrl,
  },
  velugannon_palace_0: {
    attribution,
    url: velugannonPalace0Url,
  },
  velugannon_palace_1: {
    attribution,
    url: velugannonPalace1Url,
  },
  velugannon_palace_2: {
    attribution,
    url: velugannonPalace2Url,
  },
  velugannon_palace_3: {
    attribution,
    url: velugannonPalace3Url,
  },
  velugannon_palace_4: {
    attribution,
    url: velugannonPalace4Url,
  },
  velugannon_palace_5: {
    attribution,
    url: velugannonPalace5Url,
  },
  velugannon_palace_6: {
    attribution,
    url: velugannonPalace6Url,
  },
  velugannon_palace_7: {
    attribution,
    url: velugannonPalace7Url,
  },
  velugannon_palace_8: {
    attribution,
    url: velugannonPalace8Url,
  },
  western_altepa_desert: {
    attribution,
    url: westernAltepaDesertUrl,
  },
  west_ronfaure: {
    attribution,
    url: westRonfaureUrl,
  },
  west_sarutabaruta: {
    attribution,
    url: westSarutabarutaUrl,
  },
  windurst_walls: {
    attribution,
    url: windurstWallsUrl,
  },
  windurst_waters_0: {
    attribution,
    url: windurstWaters0Url,
  },
  windurst_waters_1: {
    attribution,
    url: windurstWaters1Url,
  },
  windurst_waters_2: {
    attribution,
    url: windurstWaters2Url,
  },
  windurst_woods: {
    attribution,
    url: windurstWoodsUrl,
  },
  xarcabard: {
    attribution,
    url: xarcabardUrl,
  },
  yhoator_jungle: {
    attribution,
    url: yhoatorJungleUrl,
  },
  yughott_grotto_0: {
    attribution,
    url: yughottGrotto0Url,
  },
  yughott_grotto_1: {
    attribution,
    url: yughottGrotto1Url,
  },
  yuhtunga_jungle: {
    attribution,
    url: yuhtungaJungleUrl,
  },
  zeruhn_mines: {
    attribution,
    url: zeruhnMinesUrl,
  },
};
