import { Descriptor } from '@compass/types/maps/Descriptor';
import { getFactors } from '../factors';
import { Registry } from '../registry';
import { mapImages, noMap as noMapImage } from './images';

export const registry = new Registry(mapImages);

const noMapFactors = getFactors('no_map');

export const noMap: Descriptor = {
  name: 'no_map',
  attribution: noMapImage.attribution,
  title: noMapFactors.name,
  url: noMapImage.url,
  getBoundaries: () => noMapFactors.boundaries,
  getSiblings: () => [],
};
