import React from 'react';
import './About.css';

export function AboutPage() {
  return (
    <div className="about">
      <h1>Compass</h1>
      <h2>Helping you navigate HorizonXI</h2>
      <p>
        Compass provides a collection of tools based heavily on the AirSkyBoat
        private server codebase and with customizations made for Horizon
        specific changes. Compass is a work-in-progress; if issues are
        discovered feel free to file bugs on the Github or contact Yaedda on the
        Horizon discord or in-game.
      </p>
      <p>
        <a
          href="https://github.com/aquariumoflies/compassxi"
          title="Github link"
        >
          Github and issue tracking
        </a>
      </p>
      <p>
        favicon is from{' '}
        <a
          href="https://www.flaticon.com/free-icons/maps-and-location"
          title="maps and location icons"
        >
          Maps and location icons created by redempticon - Flaticon
        </a>
      </p>
    </div>
  );
}
