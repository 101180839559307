import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSpawnsFor } from '@compass/data/maps/spawns';
import { Spawn } from '@compass/types/maps/Spawn';
import { useMapDescriptor } from './useMapDescriptor';

export function useSpawns(): Spawn[] {
  const [searchParams] = useSearchParams();
  const { name } = useMapDescriptor();
  const mobParams = searchParams.getAll('mob') ?? '';

  return useMemo(
    () =>
      getSpawnsFor(name).filter((spawn) => mobParams.includes(spawn.groupid)),
    [mobParams, name]
  );
}
