import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Colors } from '@compass/colors';
import { AboutPage } from '@compass/pages/AboutPage';
import { SkillchainPage } from '@compass/pages/SkillchainPage';
import './App.css';
import { NavBar } from './TopNavBar';
import { Maps } from './maps/Maps';
import './vars.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: Colors.cyan,
    },
    background: {
      default: Colors.veryDarkGray,
      paper: Colors.veryDarkGray,
    },
  },
});

export function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/maps/:name?" element={<Maps />} />
          <Route path="/skillchain" element={<SkillchainPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}
