import React, { useCallback } from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import { useMapDescriptor } from '@compass/hooks/useMapDescriptor';
import { useNavigate } from '@compass/hooks/useMapsNavigate';
import { useMapSettings } from '@compass/stores/useMapSettings';
import { Zoneline } from '@compass/types/maps/Zoneline';
import { useMap } from './MapContext';

const baseColor = 'var(--blue)';
const activeColor = 'var(--purple)';

interface TransitionProps {
  isActive?: boolean;
  map: string;
  zoneline: Zoneline;
}

// Use x,y as image pixels (so 0,0 is top left)
// Leaflet uses it like a grid (so 0,0 is bottom left)
// Convert between the two
export function Transition({
  isActive,
  map,
  zoneline,
}: TransitionProps): React.ReactElement | null {
  const { id: zonelineId, src, dst } = zoneline;

  const { image } = useMap();
  const dstMap = useMapDescriptor(dst?.map ?? '');
  const navigate = useNavigate();
  const handleClick = useCallback(
    () =>
      navigate({
        map: zoneline.dst.map,
        from: zoneline.id,
      }),
    [navigate, zoneline]
  );
  const showZonelineId = useMapSettings((s) =>
    s.settings.get('showZonelineId')
  );

  if (!src || !dst) {
    return null;
  }

  // For a given map we define top left and top right coords - that can scale and translate what we need

  return (
    <Circle
      bubblingMouseEvents={false}
      center={src.toLatLng(image)}
      eventHandlers={{
        click: handleClick,
      }}
      pathOptions={{
        color: isActive ? activeColor : baseColor,
        fill: true,
        fillOpacity: 0.8,
      }}
      radius={4}
    >
      <Tooltip>
        {dstMap.title}
        {showZonelineId && <> ({zonelineId})</>}
      </Tooltip>
    </Circle>
  );
}
