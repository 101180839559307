import { Factors } from '@compass/types/maps/Factors';
import json from './factors.json';

// maps from key -> Factors
const FACTORS: Record<string, Factors> = {};
for (const record of json) {
  FACTORS[record.key] = new Factors(record);
}

// maps from parent -> Factors[]
const CHILDREN: Record<string, Factors[]> = {};

for (const key in FACTORS) {
  const record = FACTORS[key];
  if (record.key !== record.parent) {
    CHILDREN[record.parent] ??= [];
    CHILDREN[record.parent].push(record);
  }
}
for (const key in CHILDREN) {
  CHILDREN[key].sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
}

const NO_MAP = FACTORS['no_map'];

export function getFactors(key: string): Factors {
  return FACTORS[key] ?? NO_MAP;
}

export function getChildren(key: string): Factors[] {
  return CHILDREN[key] ?? [];
}

export function getMaps(): { name: string; title: string }[] {
  return Object.keys(FACTORS).map((name) => {
    const factors = FACTORS[name];
    return { name, title: factors.name };
  });
}
