import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import React, { useCallback } from 'react';
import { Environment, getEnvironment } from '@compass/helpers/environment';
import { useMapSettings } from '@compass/stores/useMapSettings';

function useBooleanEventCallback(
  cb: (value: boolean) => void
): (evt: React.ChangeEvent<HTMLInputElement>) => void {
  return useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      cb(evt.target.checked);
    },
    [cb]
  );
}

export function DevSettings(): React.ReactElement<{}, typeof DevSettings> {
  const setShowBoundaries = useBooleanEventCallback(
    useMapSettings((s) => s.setShowBoundaries)
  );
  const setShowZonelineId = useBooleanEventCallback(
    useMapSettings((s) => s.setShowZonelineId)
  );
  const settings = useMapSettings((s) => s.settings);

  if (getEnvironment() !== Environment.Development) {
    return <></>;
  }

  return (
    <Stack spacing={0} sx={{ m: 1 }} direction="column" width="100%">
      <FormControlLabel
        control={
          <Checkbox
            checked={settings.get('showBoundaries')}
            onChange={setShowBoundaries}
          />
        }
        label="Show Boundaries"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={settings.get('showZonelineId')}
            onChange={setShowZonelineId}
          />
        }
        label="Show Zoneline ID"
      />
    </Stack>
  );
}
