import { Weapon } from './Weapon';

export type WeaponSkill =
  | Axe
  | GreatAxe
  | Archery
  | Club
  | Dagger
  | HandToHand
  | Katana
  | GreatKatana
  | Marksmanship
  | Polearm
  | Scythe
  | Staff
  | Sword
  | GreatSword
  | BloodPact;

export enum Axe {
  RAGING_AXE = 'RAGING_AXE',
  SMASH_AXE = 'SMASH_AXE',
  GALE_AXE = 'GALE_AXE',
  AVALANCHE_AXE = 'AVALANCHE_AXE',
  SPINNING_AXE = 'SPINNING_AXE',
  RAMPAGE = 'RAMPAGE',
  CALAMITY = 'CALAMITY',
  MISTRAL_AXE = 'MISTRAL_AXE',
  DECIMATION = 'DECIMATION',
  // ONSLAUGHT = 'ONSLAUGHT',
}

export enum GreatAxe {
  SHIELD_BREAK = 'SHIELD_BREAK',
  IRON_TEMPEST = 'IRON_TEMPEST',
  STURMWIND = 'STURMWIND',
  ARMOR_BREAK = 'ARMOR_BREAK',
  KEEN_EDGE = 'KEEN_EDGE',
  WEAPON_BREAK = 'WEAPON_BREAK',
  RAGING_RUSH = 'RAGING_RUSH',
  FULL_BREAK = 'FULL_BREAK',
  STEEL_CYCLONE = 'STEEL_CYCLONE',
  // METATRON_TORMENT = 'METATRON_TORMENT',
}

export enum Archery {
  FLAMING_ARROW = 'FLAMING_ARROW',
  PIERCING_ARROW = 'PIERCING_ARROW',
  DULLING_ARROW = 'DULLING_ARROW',
  SIDEWINDER = 'SIDEWINDER',
  BLAST_ARROW = 'BLAST_ARROW',
  ARCHING_ARROW = 'ARCHING_ARROW',
  EMPYREAL_ARROW = 'EMPYREAL_ARROW',
  // NAMAS_ARROW = 'NAMAS_ARROW',
}

export enum Club {
  SHINING_STRIKE = 'SHINING_STRIKE',
  SERAPH_STRIKE = 'SERAPH_STRIKE',
  BRAINSHAKER = 'BRAINSHAKER',
  SKULLBREAKER = 'SKULLBREAKER',
  TRUE_STRIKE = 'TRUE_STRIKE',
  JUDGMENT = 'JUDGMENT',
  HEXA_STRIKE = 'HEXA_STRIKE',
  BLACK_HALO = 'BLACK_HALO',
  // RANDGRITH = 'RANDGRITH',
}

export enum Dagger {
  WASP_STING = 'WASP_STING',
  GUST_SLASH = 'GUST_SLASH',
  SHADOWSTITCH = 'SHADOWSTITCH',
  VIPER_BITE = 'VIPER_BITE',
  CYCLONE = 'CYCLONE',
  DANCING_EDGE = 'DANCING_EDGE',
  SHARK_BITE = 'SHARK_BITE',
  EVISCERATION = 'EVISCERATION',
  // MERCY_STROKE = 'MERCY_STROKE',
}

export enum HandToHand {
  COMBO = 'COMBO',
  SHOULDER_TACKLE = 'SHOULDER_TACKLE',
  ONE_INCH_PUNCH = 'ONE_INCH_PUNCH',
  BACKHAND_BLOW = 'BACKHAND_BLOW',
  RAGING_FISTS = 'RAGING_FISTS',
  SPINNING_ATTACK = 'SPINNING_ATTACK',
  HOWLING_FIST = 'HOWLING_FIST',
  DRAGON_KICK = 'DRAGON_KICK',
  ASURAN_FISTS = 'ASURAN_FISTS',
  // FINAL_HEAVEN = 'FINAL_HEAVEN',
}

export enum Katana {
  BLADE_RIN = 'BLADE_RIN',
  BLADE_RETSU = 'BLADE_RETSU',
  BLADE_TEKI = 'BLADE_TEKI',
  BLADE_TO = 'BLADE_TO',
  BLADE_CHI = 'BLADE_CHI',
  BLADE_EI = 'BLADE_EI',
  BLADE_JIN = 'BLADE_JIN',
  BLADE_TEN = 'BLADE_TEN',
  BLADE_KU = 'BLADE_KU',
  // BLADE_METSU = 'BLADE_METSU',
}

export enum GreatKatana {
  TACHI_ENPI = 'TACHI_ENPI',
  TACHI_HOBAKU = 'TACHI_HOBAKU',
  TACHI_GOTEN = 'TACHI_GOTEN',
  TACHI_KAGERO = 'TACHI_KAGERO',
  TACHI_JINPU = 'TACHI_JINPU',
  TACHI_KOKI = 'TACHI_KOKI',
  TACHI_YUKIKAZE = 'TACHI_YUKIKAZE',
  TACHI_GEKKO = 'TACHI_GEKKO',
  TACHI_KASHA = 'TACHI_KASHA',
  // TACHI_KAITEN = 'TACHI_KAITEN',
}

export enum Marksmanship {
  HOT_SHOT = 'HOT_SHOT',
  SPLIT_SHOT = 'SPLIT_SHOT',
  SNIPER_SHOT = 'SNIPER_SHOT',
  SLUG_SHOT = 'SLUG_SHOT',
  BLAST_SHOT = 'BLAST_SHOT',
  HEAVY_SHOT = 'HEAVY_SHOT',
  DETONATOR = 'DETONATOR',
  // CORONACH = 'CORONACH',
}

export enum Polearm {
  DOUBLE_THRUST = 'DOUBLE_THRUST',
  THUNDER_THRUST = 'THUNDER_THRUST',
  RAIDEN_THRUST = 'RAIDEN_THRUST',
  LEG_SWEEP = 'LEG_SWEEP',
  PENTA_THRUST = 'PENTA_THRUST',
  VORPAL_THRUST = 'VORPAL_THRUST',
  SKEWER = 'SKEWER',
  WHEELING_THRUST = 'WHEELING_THRUST',
  IMPULSE_DRIVE = 'IMPULSE_DRIVE',
  // GEIRSKOGUL = 'GEIRSKOGUL',
}

export enum Scythe {
  SLICE = 'SLICE',
  DARK_HARVEST = 'DARK_HARVEST',
  SHADOW_OF_DEATH = 'SHADOW_OF_DEATH',
  NIGHTMARE_SCYTHE = 'NIGHTMARE_SCYTHE',
  SPINNING_SCYTHE = 'SPINNING_SCYTHE',
  VORPAL_SCYTHE = 'VORPAL_SCYTHE',
  GUILLOTINE = 'GUILLOTINE',
  CROSS_REAPER = 'CROSS_REAPER',
  SPIRAL_HELL = 'SPIRAL_HELL',
  // CATASTROPHE = 'CATASTROPHE',
}

export enum Staff {
  HEAVY_SWING = 'HEAVY_SWING',
  ROCK_CRUSHER = 'ROCK_CRUSHER',
  EARTH_CRUSHER = 'EARTH_CRUSHER',
  STARBURST = 'STARBURST',
  SUNBURST = 'SUNBURST',
  SHELL_CRUSHER = 'SHELL_CRUSHER',
  FULL_SWING = 'FULL_SWING',
  RETRIBUTION = 'RETRIBUTION',
  // GATE_OF_TARTARUS = 'GATE_OF_TARTARUS',
}

export enum Sword {
  FAST_BLADE = 'FAST_BLADE',
  BURNING_BLADE = 'BURNING_BLADE',
  RED_LOTUS_BLADE = 'RED_LOTUS_BLADE',
  FLAT_BLADE = 'FLAT_BLADE',
  SHINING_BLADE = 'SHINING_BLADE',
  SERAPH_BLADE = 'SERAPH_BLADE',
  CIRCLE_BLADE = 'CIRCLE_BLADE',
  VORPAL_BLADE = 'VORPAL_BLADE',
  SWIFT_BLADE = 'SWIFT_BLADE',
  SAVAGE_BLADE = 'SAVAGE_BLADE',
  // KNIGHTS_OF_ROUND = 'KNIGHTS_OF_ROUND',
}

export enum GreatSword {
  HARD_SLASH = 'HARD_SLASH',
  POWER_SLASH = 'POWER_SLASH',
  FROSTBITE = 'FROSTBITE',
  FREEZEBITE = 'FREEZEBITE',
  SHOCKWAVE = 'SHOCKWAVE',
  CRESCENT_MOON = 'CRESCENT_MOON',
  SICKLE_MOON = 'SICKLE_MOON',
  SPINNING_SLASH = 'SPINNING_SLASH',
  GROUND_STRIKE = 'GROUND_STRIKE',
  // SCOURGE = 'SCOURGE',
}

export enum BloodPact {
  CLAW = 'CLAW',
  ROCK_THROW = 'ROCK_THROW',
  AXE_KICK = 'AXE_KICK',
  PUNCH = 'PUNCH',
  SHOCK_STRIKE = 'SHOCK_STRIKE',
  BARRACUDA_DIVE = 'BARRACUDA_DIVE',
  CAMISADO = 'CAMISADO',
  POISON_NAILS = 'POISON_NAILS',
  MOONLIT_CHARGE = 'MOONLIT_CHARGE',
  CRESCENT_FANG = 'CRESCENT_FANG',
  ROCK_BUSTER = 'ROCK_BUSTER',
  BURNING_STRIKE = 'BURNING_STRIKE',
  TAIL_WHIP = 'TAIL_WHIP',
  DOUBLE_PUNCH = 'DOUBLE_PUNCH',
  MEGALITH_THROW = 'MEGALITH_THROW',
  DOUBLE_SLAP = 'DOUBLE_SLAP',
}

export interface WeaponToWeaponSkillsInterface {
  [Weapon.AXE]: Axe[];
  [Weapon.GREAT_AXE]: GreatAxe[];
  [Weapon.ARCHERY]: Archery[];
  [Weapon.CLUB]: Club[];
  [Weapon.DAGGER]: Dagger[];
  [Weapon.HAND_TO_HAND]: HandToHand[];
  [Weapon.KATANA]: Katana[];
  [Weapon.GREAT_KATANA]: GreatKatana[];
  [Weapon.MARKSMANSHIP]: Marksmanship[];
  [Weapon.POLEARM]: Polearm[];
  [Weapon.SCYTHE]: Scythe[];
  [Weapon.STAFF]: Staff[];
  [Weapon.SWORD]: Sword[];
  [Weapon.GREAT_SWORD]: GreatSword[];
  [Weapon.BLOOD_PACT]: BloodPact[];
}

export const WeaponToWeaponSkills: WeaponToWeaponSkillsInterface = {
  [Weapon.AXE]: Object.values(Axe),
  [Weapon.GREAT_AXE]: Object.values(GreatAxe),
  [Weapon.ARCHERY]: Object.values(Archery),
  [Weapon.CLUB]: Object.values(Club),
  [Weapon.DAGGER]: Object.values(Dagger),
  [Weapon.HAND_TO_HAND]: Object.values(HandToHand),
  [Weapon.KATANA]: Object.values(Katana),
  [Weapon.GREAT_KATANA]: Object.values(GreatKatana),
  [Weapon.MARKSMANSHIP]: Object.values(Marksmanship),
  [Weapon.POLEARM]: Object.values(Polearm),
  [Weapon.SCYTHE]: Object.values(Scythe),
  [Weapon.STAFF]: Object.values(Staff),
  [Weapon.SWORD]: Object.values(Sword),
  [Weapon.GREAT_SWORD]: Object.values(GreatSword),
  [Weapon.BLOOD_PACT]: Object.values(BloodPact),
};
