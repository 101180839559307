import { Image } from 'image-js';
import { Record } from 'immutable';
import { LatLngTuple } from 'leaflet';
import { getChildren, getFactors } from '@compass/data/maps/factors';
import { Factors } from '@compass/types/maps/Factors';
/**
    A note on coordinates systems

    In ASB code, (x, z) represents horizontal/vertical positions and y is used for height.

    A common addon for xi (mobdb) displays position coordinates like this:
    (3.31,-41.60): Z:1.84

    We use x, y for horizontal/vertical and z for height throughout the app (consistent with mobdb)
  */

// Represents a point on the map with an optional label
interface IPoint {
  map: string;
  label?: string;
  x: number;
  y: number;
  // z can be optional in many instances
  z?: number;
}

export type PointType = Required<IPoint>;

export class Point extends Record<IPoint>({
  map: 'no_map',
  label: undefined,
  x: 0,
  y: 0,
  z: undefined,
}) {
  private factors: Factors;

  constructor(value: IPoint) {
    super(value);
    this.factors = getFactors(this.map);
  }

  // Need an image to map to LatLngTuple for leaflet
  toLatLng(image: Image): LatLngTuple {
    return [
      image.height -
        (this.y * this.factors.scaleY + this.factors.transY) *
          (image.height / 256),
      (this.x * this.factors.scaleX + this.factors.transX) *
        (image.width / 256),
    ];
  }

  // Returns the grid location like "A-8"
  // XI has 15*15 grids with a half buffer on each side
  // If we're outside the grid in any direction, just return "-"
  toGrid(image: Image): string {
    // All these are in px terms
    const width = image.width;
    const height = image.height;
    const xSize = width / 16;
    const ySize = height / 16;
    const xPadding = xSize / 2;
    const yPadding = ySize / 2;

    // Convert our point to px
    const x =
      (this.x * this.factors.scaleX + this.factors.transX) *
      (image.width / 256);
    const y =
      (this.y * this.factors.scaleY + this.factors.transY) *
      (image.height / 256);

    if (x < xPadding || x >= width - xPadding) {
      return '-';
    }
    if (y < yPadding || y >= height - yPadding) {
      return '-';
    }
    const xOffset = Math.floor((x - xPadding) / xSize);
    const yOffset = Math.floor((y - yPadding) / ySize);
    const xValue = 'ABCDEFGHIJKLMNO'[xOffset];
    const yValue = yOffset + 1;
    return `${xValue}${yValue}`;
  }

  static fromLatLng(
    map: string,
    image: Image,
    lat: number,
    lng: number
  ): Point {
    const x = Math.round(lng);
    const y = Math.round(image.height - lat);
    const factors = getFactors(map);
    return new Point({
      map,
      x: (x * (256 / image.width) - factors.transX) / factors.scaleX,
      y: (y * (256 / image.height) - factors.transY) / factors.scaleY,
    });
  }
}

function isPoint(
  value: [string, number, number, number] | IPoint | string
): value is IPoint {
  return (value as IPoint).map !== undefined;
}

function isString(
  value: [string, number, number, number] | Required<IPoint> | string
): value is string {
  return typeof value === 'string';
}

// Resolves a point from xi coords to px
export function resolve(point: [string, number, number, number]): Point;
export function resolve(point: IPoint): Point;
export function resolve(map: string, x: number, y: number, z: number): Point;
export function resolve(
  arg: [string, number, number, number] | IPoint | string,
  ...rest: number[]
): Point {
  let map = '';
  let x = 0;
  let y = 0;
  let z: number | undefined = 0;
  let label: string | undefined = undefined;

  if (isPoint(arg)) {
    ({ map, label, x, y, z } = arg);
  } else if (isString(arg)) {
    [map, x, y, z] = [arg, ...rest];
  } else {
    [map, x, y, z] = arg;
  }

  const factors =
    getChildren(map).find((record) => record.contains({ x, y, z })) ??
    getFactors(map);

  return new Point({ map: factors.key, label, x, y, z });
}
