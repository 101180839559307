import React from 'react';
import { Circle } from 'react-leaflet';
import { getChestsFor, getCoffersFor } from '@compass/data/maps/treasures';
import { useMapDescriptor } from '@compass/hooks/useMapDescriptor';
import { useMapSettings } from '@compass/stores/useMapSettings';
import { useMap } from './MapContext';

const chestColor = 'var(--green)';
const cofferColor = 'var(--blue)';

export function Treasures(): React.ReactElement<{}, typeof Treasures> {
  const showChests = useMapSettings((s) => s.settings.get('showChests'));
  const showCoffers = useMapSettings((s) => s.settings.get('showCoffers'));
  const { name } = useMapDescriptor();
  const { image } = useMap();

  const chests = getChestsFor(name);
  const coffers = getCoffersFor(name);

  return (
    <>
      {showChests && (
        <>
          {chests.map((point, idx) => (
            <Circle
              bubblingMouseEvents={false}
              center={point.toLatLng(image)}
              key={idx}
              pathOptions={{
                color: chestColor,
                fill: true,
                fillOpacity: 1.0,
              }}
              radius={4}
            />
          ))}
        </>
      )}
      {showCoffers && (
        <>
          {coffers.map((point, idx) => (
            <Circle
              bubblingMouseEvents={false}
              center={point.toLatLng(image)}
              key={idx}
              pathOptions={{
                color: cofferColor,
                fill: true,
                fillOpacity: 1.0,
              }}
              radius={4}
            />
          ))}
        </>
      )}
    </>
  );
}
