// area and id we take from AirSkyBoat code
// https://github.com/AirSkyBoat/AirSkyBoat/blob/staging/scripts/globals/quests.lua
import { resolve } from '@compass/types/maps/Point';
import { Spawn } from '@compass/types/maps/Spawn';
import json from './spawns.json';

const spawns: Spawn[] = [];

for (const spawn of json) {
  const point = resolve(spawn);
  if (point.map === 'no_map') {
    console.warn(`Found a mob spawn for non-existant map ${spawn.map}`);
    continue;
  }
  spawns.push({
    groupid: spawn.groupid,
    mob: spawn.mob,
    point,
  });
}

export function getSpawnsFor(map: string, mob?: string): Spawn[] {
  return spawns.filter((s) => s.point.map === map && (!mob || s.mob === mob));
}
