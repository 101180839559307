import React from 'react';
import { Circle, Tooltip } from 'react-leaflet';
import { useSpawns } from '@compass/hooks/useSpawns';
import { useMap } from './MapContext';

const baseColor = 'var(--orange)';

export function SpawnMarkers(): React.ReactElement<{}, typeof SpawnMarkers> {
  const { image } = useMap();
  const spawns = useSpawns();

  return (
    <>
      {spawns.map((spawn) => (
        <Circle
          bubblingMouseEvents={false}
          center={spawn.point.toLatLng(image)}
          pathOptions={{
            color: baseColor,
            fill: true,
            fillOpacity: 0.8,
          }}
          radius={4}
        >
          <Tooltip>{spawn?.mob ?? 'Unknown Quest'}</Tooltip>
        </Circle>
      ))}
    </>
  );
}
