import L from 'leaflet';
import React from 'react';
import { Circle, Rectangle, Tooltip } from 'react-leaflet';
import { useQuest } from '@compass/hooks/useQuest';
import { isPoint, Location } from '@compass/types/maps/Quest';
import { useMap } from './MapContext';

const baseColor = 'var(--red)';

interface QuestMarkerProps {
  location: Location;
}

// Use x,y as image pixels (so 0,0 is top left)
// Leaflet uses it like a grid (so 0,0 is bottom left)
// Convert between the two
export function QuestMarker({
  location,
}: QuestMarkerProps): React.ReactElement<QuestMarkerProps, typeof QuestMarker> {
  const { image } = useMap();
  const [quest] = useQuest();

  if (isPoint(location)) {
    return (
      <Circle
        bubblingMouseEvents={false}
        center={location.toLatLng(image)}
        pathOptions={{
          color: baseColor,
          fill: true,
          fillOpacity: 0.8,
        }}
        radius={4}
      >
        <Tooltip>{location.label ?? quest?.name ?? 'Unknown Quest'}</Tooltip>
      </Circle>
    );
  } else {
    // Trigger area can be two different types
    if (!location.second) {
      return (
        <Circle
          bubblingMouseEvents={false}
          center={location.first.toLatLng(image)}
          pathOptions={{
            color: baseColor,
            fill: true,
            fillOpacity: 0.8,
          }}
          radius={location.first.z}
        >
          <Tooltip>{location.label ?? quest?.name ?? 'Unknown Quest'}</Tooltip>
        </Circle>
      );
    }

    // If we're here, second is defined so must be rectangle area
    return (
      <Rectangle
        bubblingMouseEvents={false}
        bounds={L.latLngBounds([
          location.first.toLatLng(image),
          location.second.toLatLng(image),
        ])}
        pathOptions={{
          color: baseColor,
          fill: true,
          fillOpacity: 0.8,
        }}
      >
        <Tooltip>{quest?.name ?? 'Unknown Quest'}</Tooltip>
      </Rectangle>
    );
  }
}
