import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { registry as remapsterRegistry } from '@compass/data/maps/remapster';
import { noMap, registry as vanillaRegistry } from '@compass/data/maps/vanilla';
import { useMapSettings } from '@compass/stores/useMapSettings';
import { Descriptor } from '@compass/types/maps/Descriptor';

function useResolver(): (name: string | undefined) => Descriptor {
  const useVanillaMaps = useMapSettings((s) =>
    s.settings.get('useVanillaMaps')
  );
  const registries = useMemo(
    () =>
      useVanillaMaps ? [vanillaRegistry] : [remapsterRegistry, vanillaRegistry],
    [useVanillaMaps]
  );
  return useCallback(
    (name) =>
      registries.map((reg) => reg.getDescriptor(name)).find(Boolean) ?? noMap,
    [registries]
  );
}

export function useMapDescriptor(name?: string): Descriptor {
  const params = useParams<{ name?: string }>();
  name ??= params.name;
  const resolver = useResolver();

  return useMemo(() => resolver(name), [name, resolver]);
}

export function useMapDescriptors(names: string[]): Descriptor[] {
  const resolver = useResolver();

  return useMemo(() => names.map(resolver), [names, resolver]);
}
