import { sha256 } from 'js-sha256';
import { resolve } from '@compass/types/maps/Point';
import { Zoneline } from '@compass/types/maps/Zoneline';
import internalJson from './internal_zonelines.json';
import json from './zonelines.json';

const zonelines: Zoneline[] = [];

for (const zoneline of json) {
  const srcPoint = resolve({ ...zoneline.src });
  const dstPoint = resolve({ ...zoneline.dst });

  if (srcPoint.map === 'no_map') {
    console.warn(`Found a zoneline for non-existant map ${zoneline.src.map}`);
    continue;
  }
  if (dstPoint.map === 'no_map') {
    console.warn(`Found a zoneline for non-existant map ${zoneline.dst.map}`);
    continue;
  }

  zonelines.push(
    new Zoneline({
      id: zoneline.id,
      dst: dstPoint,
      src: srcPoint,
    })
  );
}

for (const zoneline of internalJson) {
  const srcPoint = resolve({ ...zoneline.src });
  const dstPoint = resolve({ ...zoneline.dst });

  if (srcPoint.map === 'no_map') {
    console.warn(`Found a zoneline for non-existant map ${zoneline.src.map}`);
    continue;
  }
  if (dstPoint.map === 'no_map') {
    console.warn(`Found a zoneline for non-existant map ${zoneline.dst.map}`);
    continue;
  }

  zonelines.push(
    new Zoneline({
      id: sha256(JSON.stringify(srcPoint)).slice(0, 8),
      dst: dstPoint,
      src: srcPoint,
    })
  );
}

export function getZonelinesFor(map: string): Zoneline[] {
  return zonelines.filter((zl) => zl.src.map === map);
}

export function getZoneline(id: string): Zoneline | undefined {
  return zonelines.find((zl) => zl.id === id);
}
