import L from 'leaflet';
import React, { useEffect, useMemo, useState } from 'react';
import { ImageOverlay, useMapEvents } from 'react-leaflet';
import { useSearchParams } from 'react-router-dom';
import { getZoneline, getZonelinesFor } from '@compass/data/maps/zonelines';
import { useMapDescriptor } from '@compass/hooks/useMapDescriptor';
import { useNavigate } from '@compass/hooks/useMapsNavigate';
import { useQuest } from '@compass/hooks/useQuest';
import { Point, resolve } from '@compass/types/maps/Point';
import { Location } from '@compass/types/maps/Quest';
import { Boundaries } from './Boundaries';
import { FfxiCoordMarker } from './FfxiCoordMarker';
import { useMap } from './MapContext';
import { Marker, MarkerElement } from './Marker';
import { QuestMarker } from './QuestMarker';
import { SpawnMarkers } from './SpawnMarkers';
import { Transition } from './Transition';
import { Treasures } from './Treasures';

// Return true if numbers are sufficiently near to be the same
function near(one: number, two: number): boolean {
  return Math.abs(one - two) <= 0.00001;
}

interface MapComponentProps {
  children?: MarkerElement | MarkerElement[];
  setCoords: (coords: Point) => void;
}

function useQuestLocations(map: string): Location[] {
  const [, step] = useQuest();
  if (!step) {
    return [];
  }

  return step.location.filter((loc) => loc.map === map);
}

// Parse point values from the params as best as we can
// May also be a XI grid like ['A', 8]
function useParsePoint(): Point | [string, number] | undefined {
  const { name } = useMapDescriptor();
  const [searchParams] = useSearchParams();
  const xParam = searchParams.get('x');
  const yParam = searchParams.get('y');
  const zParam = searchParams.get('z');

  return useMemo(() => {
    const x = parseFloat(xParam || '');
    const y = parseFloat(yParam || '');
    const z = parseFloat(zParam || '');

    // Grid - x is a letter, y ix a number
    if (isNaN(x) && !isNaN(y) && xParam) {
      return [xParam, y];
    }

    // Not a full coordinate
    if (isNaN(x) || isNaN(y)) {
      return undefined;
    }

    // if z is given, resolve to pixels
    if (!isNaN(z)) {
      return resolve({ map: name, x, y, z });
    }

    return new Point({ map: name, x, y });
  }, [name, xParam, yParam, zParam]);
}

function isPoint(value: Point | [string, number] | undefined): value is Point {
  return value !== undefined && !Array.isArray(value);
}

function isGrid(
  value: Point | [string, number] | undefined
): value is [string, number] {
  return Array.isArray(value);
}

export function MapComponent({
  children,
  setCoords,
}: MapComponentProps): React.ReactElement<
  MapComponentProps,
  typeof MapComponent
> {
  const { attribution, name } = useMapDescriptor();
  const { map, blob, image } = useMap();
  const [searchParams] = useSearchParams();
  const [opacity, setOpacity] = useState(0);
  const navigate = useNavigate();
  const point = useParsePoint();

  const zoom = parseInt(searchParams.get('zm') || '');
  const from = searchParams.get('from');
  const zonelines = getZonelinesFor(name);
  const questPoints = useQuestLocations(name);

  const bounds = useMemo(
    () => L.latLngBounds([0, 0], [image.height ?? 0, image.width ?? 0]),
    [image]
  );

  useEffect(() => {
    if (!isNaN(zoom)) {
      map.setZoom(zoom);
    }
  }, [map, zoom]);

  useEffect(
    () => {
      if (isPoint(point)) {
        map.panTo(point.toLatLng(image));
      } else {
        map.panTo([image.height / 2, image.width / 2]);
      }
    },
    // Only do this on the first page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useMapEvents({
    click: (evt) => {
      const { lat, lng } = evt.latlng;
      if (lat < 0 || lat >= image.width || lng < 0 || lng >= image.height) {
        return;
      }
      const point = Point.fromLatLng(name, image, lat, lng);
      navigate({ x: point.x.toFixed(3), y: point.y.toFixed(3) });
    },
    mousemove: (evt) => {
      const { lat, lng } = evt.latlng;
      const point = Point.fromLatLng(name, image, lat, lng);
      setCoords(point);
    },
  });

  // Provide a transition effect
  useEffect(() => {
    setOpacity(0);
    const interval = setInterval(() => {
      setOpacity((currValue: number) => {
        if (currValue >= 1) {
          clearInterval(interval);
          return 1;
        }
        return (currValue += 0.125);
      });
      return () => clearInterval(interval);
    }, 50);
  }, [name]);

  // If we came from another map, find the matching zoneline (if there is one)
  const fromZoneline = useMemo(() => {
    if (!from) {
      return undefined;
    }
    const zoneline = getZoneline(from);
    if (!zoneline) {
      return undefined;
    }
    for (const zl of zonelines) {
      if (near(zl.src.x, zoneline.dst.x) && near(zl.src.y, zoneline.dst.y)) {
        return zl.id;
      }
    }
  }, [from, zonelines]);

  return (
    <ImageOverlay
      url={window.URL.createObjectURL(blob)}
      bounds={bounds}
      attribution={attribution}
      opacity={opacity}
    >
      {children}
      {zonelines.map((zoneline) => (
        <Transition
          key={zoneline.id}
          isActive={fromZoneline === zoneline.id}
          zoneline={zoneline}
          map={name}
        />
      ))}
      {isGrid(point) && <FfxiCoordMarker x={point[0]} y={point[1]} />}
      {isPoint(point) && <Marker point={point} />}
      {questPoints.map((point, idx) => (
        <QuestMarker key={idx} location={point} />
      ))}
      <SpawnMarkers />
      <Boundaries />
      <Treasures />
    </ImageOverlay>
  );
}
