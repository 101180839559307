import { Map, List, Set } from 'immutable';
import { Attribute as A, Attribute } from './Attribute';
import {
  Archery,
  Axe,
  BloodPact,
  Club,
  Dagger,
  GreatAxe,
  GreatKatana,
  GreatSword,
  HandToHand,
  Katana,
  Marksmanship,
  Polearm,
  Scythe,
  Staff,
  Sword,
  WeaponSkill,
} from './WeaponSkill';

export const WeaponSkillAttributes = Map<WeaponSkill, List<Attribute>>(
  List([
    // Axe
    [Axe.RAGING_AXE, List([A.DETONATION, A.IMPACTION])],
    [Axe.SMASH_AXE, List([A.INDURATION, A.REVERBERATION])],
    [Axe.GALE_AXE, List([A.DETONATION])],
    [Axe.AVALANCHE_AXE, List([A.INDURATION])],
    [Axe.SPINNING_AXE, List([A.LIQUEFACTION, A.SCISSION])],
    [Axe.RAMPAGE, List([A.SCISSION])],
    [Axe.CALAMITY, List([A.SCISSION, A.IMPACTION])],
    [Axe.MISTRAL_AXE, List([A.FUSION])],
    [Axe.DECIMATION, List([A.FUSION, A.DETONATION])],
    // Great Axe
    [GreatAxe.SHIELD_BREAK, List([A.IMPACTION])],
    [GreatAxe.IRON_TEMPEST, List([A.SCISSION])],
    [GreatAxe.STURMWIND, List([A.REVERBERATION, A.SCISSION])],
    [GreatAxe.ARMOR_BREAK, List([A.IMPACTION])],
    [GreatAxe.KEEN_EDGE, List([A.COMPRESSION])],
    [GreatAxe.WEAPON_BREAK, List([A.IMPACTION])],
    [GreatAxe.RAGING_RUSH, List([A.INDURATION, A.REVERBERATION])],
    [GreatAxe.FULL_BREAK, List([A.DISTORTION])],
    [GreatAxe.STEEL_CYCLONE, List([A.DISTORTION, A.DETONATION])],
    // Archery
    [Archery.FLAMING_ARROW, List([A.LIQUEFACTION, A.TRANSFIXION])],
    [Archery.PIERCING_ARROW, List([A.INDURATION, A.TRANSFIXION])],
    [Archery.DULLING_ARROW, List([A.LIQUEFACTION, A.TRANSFIXION])],
    [Archery.SIDEWINDER, List([A.REVERBERATION, A.TRANSFIXION, A.DETONATION])],
    [Archery.BLAST_ARROW, List([A.INDURATION, A.TRANSFIXION])],
    [Archery.ARCHING_ARROW, List([A.FUSION])],
    [Archery.EMPYREAL_ARROW, List([A.FUSION, A.TRANSFIXION])],
    // Club
    [Club.SHINING_STRIKE, List([A.TRANSFIXION])],
    [Club.BRAINSHAKER, List([A.REVERBERATION])],
    [Club.SERAPH_STRIKE, List([A.SCISSION])],
    [Club.SKULLBREAKER, List([A.INDURATION, A.REVERBERATION])],
    [Club.TRUE_STRIKE, List([A.DETONATION, A.IMPACTION])],
    [Club.JUDGMENT, List([A.IMPACTION])],
    [Club.HEXA_STRIKE, List([A.FUSION])],
    [Club.BLACK_HALO, List([A.FRAGMENTATION, A.COMPRESSION])],
    // Dagger
    [Dagger.WASP_STING, List([A.SCISSION])],
    [Dagger.GUST_SLASH, List([A.DETONATION])],
    [Dagger.SHADOWSTITCH, List([A.REVERBERATION])],
    [Dagger.VIPER_BITE, List([A.SCISSION])],
    [Dagger.CYCLONE, List([A.DETONATION, A.IMPACTION])],
    [Dagger.DANCING_EDGE, List([A.SCISSION, A.DETONATION])],
    [Dagger.SHARK_BITE, List([A.FRAGMENTATION])],
    [Dagger.EVISCERATION, List([A.GRAVITATION, A.TRANSFIXION])],
    // Hand-to-Hand
    [HandToHand.COMBO, List([A.IMPACTION])],
    [HandToHand.SHOULDER_TACKLE, List([A.REVERBERATION, A.IMPACTION])],
    [HandToHand.ONE_INCH_PUNCH, List([A.COMPRESSION])],
    [HandToHand.BACKHAND_BLOW, List([A.DETONATION])],
    [HandToHand.RAGING_FISTS, List([A.IMPACTION])],
    [HandToHand.SPINNING_ATTACK, List([A.LIQUEFACTION, A.IMPACTION])],
    [HandToHand.HOWLING_FIST, List([A.TRANSFIXION, A.IMPACTION])],
    [HandToHand.DRAGON_KICK, List([A.FRAGMENTATION])],
    [HandToHand.ASURAN_FISTS, List([A.GRAVITATION, A.LIQUEFACTION])],
    // Katana
    [Katana.BLADE_RIN, List([A.TRANSFIXION])],
    [Katana.BLADE_RETSU, List([A.SCISSION])],
    [Katana.BLADE_TEKI, List([A.REVERBERATION])],
    [Katana.BLADE_TO, List([A.INDURATION, A.DETONATION])],
    [Katana.BLADE_CHI, List([A.TRANSFIXION, A.IMPACTION])],
    [Katana.BLADE_EI, List([A.COMPRESSION])],
    [Katana.BLADE_JIN, List([A.DETONATION, A.IMPACTION])],
    [Katana.BLADE_TEN, List([A.GRAVITATION])],
    [Katana.BLADE_KU, List([A.GRAVITATION, A.TRANSFIXION])],
    // Great Katana
    [GreatKatana.TACHI_ENPI, List([A.TRANSFIXION, A.SCISSION])],
    [GreatKatana.TACHI_HOBAKU, List([A.INDURATION])],
    [GreatKatana.TACHI_GOTEN, List([A.TRANSFIXION, A.IMPACTION])],
    [GreatKatana.TACHI_KAGERO, List([A.LIQUEFACTION])],
    [GreatKatana.TACHI_JINPU, List([A.SCISSION, A.DETONATION])],
    [GreatKatana.TACHI_KOKI, List([A.REVERBERATION, A.IMPACTION])],
    [GreatKatana.TACHI_YUKIKAZE, List([A.INDURATION, A.DETONATION])],
    [GreatKatana.TACHI_GEKKO, List([A.DISTORTION, A.REVERBERATION])],
    [GreatKatana.TACHI_KASHA, List([A.FUSION, A.COMPRESSION])],
    // Marksmanship
    [Marksmanship.HOT_SHOT, List([A.LIQUEFACTION, A.TRANSFIXION])],
    [Marksmanship.SPLIT_SHOT, List([A.REVERBERATION, A.TRANSFIXION])],
    [Marksmanship.SNIPER_SHOT, List([A.LIQUEFACTION, A.TRANSFIXION])],
    [
      Marksmanship.SLUG_SHOT,
      List([A.REVERBERATION, A.TRANSFIXION, A.DETONATION]),
    ],
    [Marksmanship.BLAST_SHOT, List([A.INDURATION, A.TRANSFIXION])],
    [Marksmanship.HEAVY_SHOT, List([A.FUSION])],
    [Marksmanship.DETONATOR, List([A.FUSION, A.TRANSFIXION])],
    // Polearm
    [Polearm.DOUBLE_THRUST, List([A.TRANSFIXION])],
    [Polearm.THUNDER_THRUST, List([A.TRANSFIXION, A.IMPACTION])],
    [Polearm.RAIDEN_THRUST, List([A.TRANSFIXION, A.IMPACTION])],
    [Polearm.LEG_SWEEP, List([A.IMPACTION])],
    [Polearm.PENTA_THRUST, List([A.COMPRESSION])],
    [Polearm.VORPAL_THRUST, List([A.REVERBERATION, A.TRANSFIXION])],
    [Polearm.SKEWER, List([A.TRANSFIXION, A.IMPACTION])],
    [Polearm.WHEELING_THRUST, List([A.FUSION])],
    [Polearm.IMPULSE_DRIVE, List([A.GRAVITATION, A.INDURATION])],
    // Scythe
    [Scythe.SLICE, List([A.SCISSION])],
    [Scythe.DARK_HARVEST, List([A.COMPRESSION])],
    [Scythe.SHADOW_OF_DEATH, List([A.INDURATION, A.REVERBERATION])],
    [Scythe.NIGHTMARE_SCYTHE, List([A.COMPRESSION, A.SCISSION])],
    [Scythe.SPINNING_SCYTHE, List([A.REVERBERATION, A.SCISSION])],
    [Scythe.VORPAL_SCYTHE, List([A.TRANSFIXION, A.SCISSION])],
    [Scythe.GUILLOTINE, List([A.INDURATION])],
    [Scythe.CROSS_REAPER, List([A.DISTORTION])],
    [Scythe.SPIRAL_HELL, List([A.GRAVITATION, A.COMPRESSION])],
    // STAFF
    [Staff.HEAVY_SWING, List([A.IMPACTION])],
    [Staff.ROCK_CRUSHER, List([A.IMPACTION])],
    [Staff.EARTH_CRUSHER, List([A.DETONATION, A.IMPACTION])],
    [Staff.STARBURST, List([A.COMPRESSION, A.TRANSFIXION])],
    [Staff.SUNBURST, List([A.TRANSFIXION, A.REVERBERATION])],
    [Staff.SHELL_CRUSHER, List([A.DETONATION])],
    [Staff.FULL_SWING, List([A.LIQUEFACTION, A.IMPACTION])],
    [Staff.RETRIBUTION, List([A.GRAVITATION, A.REVERBERATION])],
    // Sword
    [Sword.FAST_BLADE, List([A.SCISSION])],
    [Sword.BURNING_BLADE, List([A.LIQUEFACTION])],
    [Sword.RED_LOTUS_BLADE, List([A.LIQUEFACTION, A.DETONATION])],
    [Sword.FLAT_BLADE, List([A.IMPACTION])],
    [Sword.SHINING_BLADE, List([A.SCISSION])],
    [Sword.SERAPH_BLADE, List([A.SCISSION, A.TRANSFIXION])],
    [Sword.CIRCLE_BLADE, List([A.REVERBERATION, A.IMPACTION])],
    [Sword.VORPAL_BLADE, List([A.SCISSION, A.IMPACTION])],
    [Sword.SWIFT_BLADE, List([A.GRAVITATION])],
    [Sword.SAVAGE_BLADE, List([A.FRAGMENTATION, A.SCISSION])],
    // Great Sword
    [GreatSword.HARD_SLASH, List([A.SCISSION])],
    [GreatSword.POWER_SLASH, List([A.TRANSFIXION])],
    [GreatSword.FROSTBITE, List([A.INDURATION])],
    [GreatSword.FREEZEBITE, List([A.INDURATION, A.DETONATION])],
    [GreatSword.SHOCKWAVE, List([A.REVERBERATION])],
    [GreatSword.CRESCENT_MOON, List([A.SCISSION])],
    [GreatSword.SICKLE_MOON, List([A.SCISSION, A.IMPACTION])],
    [GreatSword.SPINNING_SLASH, List([A.FRAGMENTATION])],
    [GreatSword.GROUND_STRIKE, List([A.FRAGMENTATION, A.DISTORTION])],
    // Blood Pact
    [BloodPact.CLAW, List([A.DETONATION])],
    [BloodPact.ROCK_THROW, List([A.SCISSION, A.DISTORTION])],
    [BloodPact.AXE_KICK, List([A.INDURATION])],
    [BloodPact.PUNCH, List([A.LIQUEFACTION])],
    [BloodPact.SHOCK_STRIKE, List([A.IMPACTION, A.FUSION])],
    [BloodPact.BARRACUDA_DIVE, List([A.REVERBERATION, A.FRAGMENTATION])],
    [BloodPact.POISON_NAILS, List([A.TRANSFIXION])],
    [BloodPact.ROCK_BUSTER, List([A.REVERBERATION, A.FRAGMENTATION])],
    [BloodPact.BURNING_STRIKE, List([A.IMPACTION, A.FUSION])],
    [BloodPact.TAIL_WHIP, List([A.DETONATION])],
    [BloodPact.DOUBLE_PUNCH, List([A.COMPRESSION, A.GRAVITATION])],
    [BloodPact.MEGALITH_THROW, List([A.INDURATION])],
    [BloodPact.DOUBLE_SLAP, List([A.SCISSION, A.DISTORTION])],
    [BloodPact.MOONLIT_CHARGE, List([A.COMPRESSION, A.GRAVITATION])],
    [BloodPact.CRESCENT_FANG, List([A.TRANSFIXION])],
    [BloodPact.CAMISADO, List([A.COMPRESSION, A.GRAVITATION])],
  ])
);

export const AttributeToSkills = WeaponSkillAttributes.reduce(
  (acc, [A, B, C], ws) => {
    if (A) acc = acc.update(A, (wsSet = Set()) => wsSet?.add(ws));
    if (B) acc = acc.update(B, (wsSet = Set()) => wsSet?.add(ws));
    if (C) acc = acc.update(C, (wsSet = Set()) => wsSet?.add(ws));

    return acc;
  },
  Map<Attribute, Set<WeaponSkill>>()
);
